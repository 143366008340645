import './error-modal.scss'
import ButtonAtomComponent from '../../components/atoms/button-atom/button-atom-component.jsx'
import ModalOrganismComponent from '../../components/organisms/modal-organism/modal-organism-component.jsx'
import ErrorModel from '../../../models/ErrorModel.js'

export default function ErrorModal(props) {
    return (
        <ModalOrganismComponent
            class="error-modal"
            title="Error occurred"
            onClose={props.onClose}
            seamlessSections={true}
        >
            <div class="error-modal__content">
                <Show when={props.error instanceof ErrorModel}>
                    <div class="error-modal__message">Message: {props.error.toString()}</div>
                    <div class="error-modal__stack">Stack: {props.error.getStack()}</div>
                </Show>
                <ButtonAtomComponent class="error-modal__close-button" onClick={props.onClose}>
                    Close
                </ButtonAtomComponent>
            </div>
        </ModalOrganismComponent>
    )
}
