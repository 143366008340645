import BaseAPI from "./base-api-client.js";
import { fetchResponseHandler } from "./error-utils.js";

export default class BookingAPI extends BaseAPI {
    static async create(bookingData) {
        try {
            const response = await this.fetchSecure('/booking', {
                method: 'POST',
                body: bookingData
            });

            return await fetchResponseHandler(response);
        } catch (error) {
            throw error;
        }
    }

    static async listByPropertiesProfileId() {
        try {
            const response = await this.fetchSecure('/booking/properties', {
                method: 'GET'
            });

            return await fetchResponseHandler(response);
        } catch (error) {
            throw error;
        }
    }

    static async listByProfileId() {
        try {
            const response = await this.fetchSecure('/booking', {
                method: 'GET'
            });

            return await fetchResponseHandler(response);
        } catch (error) {
            throw error;
        }
    }

    static async show(bookingId) {
        try {
            const response = await this.fetchSecure(`/booking/${bookingId}`, {
                method: 'GET'
            });

            return await fetchResponseHandler(response);
        } catch (error) {
            throw error;
        }
    }

    static async update(bookingId, bookingData) {
        try {
            const response = await this.fetchSecure(`/booking/${bookingId}`, {
                method: 'PATCH',
                body: bookingData
            });

            return await fetchResponseHandler(response);
        } catch (error) {
            throw error;
        }
    }

    static async cancel(bookingId, reasonString) {
        try {
            const response = await this.fetchSecure(`/booking/${bookingId}/cancel`, {
                method: 'PATCH',
                body: {
                    cancel_reason: reasonString
                }
            });

            return await fetchResponseHandler(response);
        } catch (error) {
            throw error;
        }
    }

    static async accept(bookingId) {
        try {
            const response = await this.fetchSecure(`/booking/${bookingId}/accept`, {
                method: 'PATCH'
            });

            return await fetchResponseHandler(response);
        } catch (error) {
            throw error;
        }
    }

    static async pay(bookingId) {
        try {
            const response = await this.fetchSecure(`/booking/${bookingId}/pay`, {
                method: 'PATCH'
            });

            return await fetchResponseHandler(response);
        } catch (error) {
            throw error;
        }
    }

    static async reject(bookingId, reasonString) {
        try {
            const response = await this.fetchSecure(`/booking/${bookingId}/reject`, {
                method: 'PATCH',
                body: {
                    reject_reason: reasonString
                }
            });

            return await fetchResponseHandler(response);
        } catch (error) {
            throw error;
        }
    }

    static async updatePrice(bookingId, bookingUrl) {
        try {
            const response = await this.fetchSecure(`/booking/${bookingId}/price`, {
                method: 'POST',
                body: {
                    bookingUrl: bookingUrl
                }
            });

            return await fetchResponseHandler(response);
        } catch (error) {
            throw error;
        }
    }

    static async pay(bookingId, confirmationId) {
        try {
            const response = await this.fetchSecure(`/booking/${bookingId}/pay`, {
                method: 'PATCH',
                body: { confirmation_id : confirmationId }
            });

            return await fetchResponseHandler(response);
        } catch (error) {
            throw error;
        }
    }

    static async search(body) {
        try {
            const response = await this.fetchSecure('/booking/search', {
                method: 'POST',
                body
            })

            return await fetchResponseHandler(response)
        } catch (error) {
            throw error
        }
    }

    static async report(bookingId, body) {
        try {
            const response = await this.fetchSecure(`/booking/${bookingId}/report`, {
                method: 'POST',
                body: { message: body }
            })

            return await fetchResponseHandler(response)
        } catch (error) {
            throw error
        }
    }

    static async checkDuplicate(body) {
        try {
            const response = await this.fetchSecure('/booking/check-duplicate', {
                method: 'POST',
                body
            })

            return await fetchResponseHandler(response)
        } catch (error) {
            throw error
        }
    }

    static async contact(message, bookingId) {
        try {
            const response = await this.fetchSecure(`/booking/${bookingId}/contact`, {
                method: 'POST',
                body:  { message : message }
            })
            console.log('Message Sent')
            return await fetchResponseHandler(response)
        } catch (error) {
            console.error(`Error sending message to recipient of booking with ID: ${bookingId} - `, error);
            throw error;
        }
    }
}
