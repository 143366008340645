// -- External libraries
import { Show, splitProps } from 'solid-js'
import { Portal } from 'solid-js/web'

import { useModalOrganismComponentController } from './modal-organism-component-controller.js'
import './modal-organism-component.scss'
import ButtonAtomComponent from '../../atoms/button-atom/button-atom-component.jsx'
import CardAtomComponent from '../../atoms/card-atom/card-atom-component.jsx'

export default function ModalOrganismComponent(props) {
    const { mergedProps } = useModalOrganismComponentController(props)
    const requiredAttributes = ['title', 'footer', 'onClose', 'noClose', 'seamlessSections']
    const [reqAttrs, styles, attrs] = splitProps(mergedProps, requiredAttributes, ['class'])

    const rootClasses = ['modal-organism-component']
    if (styles.class) rootClasses.push(styles.class)

    return (
        <Portal {...attrs}>
            <div class={rootClasses.join(' ')}>
                <div class='modal-organism-component__backdrop' onClick={() => { !reqAttrs.noClose && reqAttrs.onClose() }}>
                    <CardAtomComponent class='modal-organism-component__card' shadow={true} onClick={(e) => e.stopPropagation()}>
                        <div class={'modal-organism-component__card__header' + (reqAttrs.seamlessSections ? ' modal-organism-component__card__header--seamless' : '')}>
                            <div class='modal-organism-component__card__header__title'>{reqAttrs.title}</div>
                            <Show when={!reqAttrs.noClose}>
                                <ButtonAtomComponent class='modal-organism-component__card__header__close-button' onClick={() => reqAttrs.onClose()}>
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.609 0.295245C11.2184 -0.0952796 10.5922 -0.102233 10.1927 0.297316L5.9521 4.53789L1.71153 0.297316C1.34999 -0.0642238 0.784207 -0.0951447 0.390004 0.211537L0.295245 0.295245C-0.0952796 0.685769 -0.102233 1.31198 0.297316 1.71153L4.53789 5.9521L0.297316 10.1927C-0.0642238 10.5542 -0.0951447 11.12 0.211537 11.5142L0.295245 11.609C0.685769 11.9995 1.31198 12.0064 1.71153 11.6069L5.9521 7.36631L10.1927 11.6069C10.5542 11.9684 11.12 11.9993 11.5142 11.6927L11.609 11.609C11.9995 11.2184 12.0064 10.5922 11.6069 10.1927L7.36631 5.9521L11.6069 1.71153C11.9684 1.34999 11.9993 0.784207 11.6927 0.390004L11.609 0.295245Z" fill="#343A40" />
                                    </svg>
                                </ButtonAtomComponent>
                            </Show>
                        </div>
                        <div class={'modal-organism-component__card__body' + (reqAttrs.seamlessSections ? ' modal-organism-component__card__body--seamless' : '')}>
                            {attrs.children}
                        </div>
                        <Show when={reqAttrs.footer}>
                            <div class={'modal-organism-component__card__footer' + (reqAttrs.seamlessSections ? ' modal-organism-component__card__footer--seamless' : '')}>
                                {reqAttrs.footer}
                            </div>
                        </Show>
                    </CardAtomComponent>
                </div>
            </div>
        </Portal>
    )
}
