import ErrorModel from "../models/ErrorModel.js"
import BaseObjectStore from "./base/base-object-store.js"

class ErrorStore extends BaseObjectStore {
    constructor() {
        super({
            errors: [],
        })
    }

    getErrors() {
        return this.get('errors')
    }

    addError(error, stack) {
        // Extract the error message from the error object
        const message = error instanceof Error ? error.message : String(error)

        const newError = new ErrorModel({
            message,
            stack,
            error
        })

        this.setState('errors', [...this.getErrors(), newError])
    }

    removeError(error) {
        this.setState('errors', this.getErrors().filter(e => e !== error))
    }

    clearErrors() {
        this.setState('errors', [])
    }
}

export default new ErrorStore()